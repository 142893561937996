import React from 'react';

import {
  SolidInfoSvg,
} from '../../constants/svgs'

import i18next from 'i18next'

import {
  formatPercentage,
  formatUnitNumber,
} from '../../languageUtils'

const getFillColor = (value, baselineAvailable, valueBaseline, valueBaselineStdDev, isIncreasingStatistic) => {
  if (!baselineAvailable) {
    return "#000000"
  } else {
    return "#000000"
    // const baselineDeviation = (value - valueBaseline)
    // if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
    //   return "#000000"
    // } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
    //   return isIncreasingStatistic ? "#93E29A" : "#FF7CBC"
    // } else {
    //   return isIncreasingStatistic ? "#FF7CBC" : "#93E29A"
    // }
  }
}


const HrvResultsPanel = ({
  title,
  value,
  valueDecimalPoints,
  isPercentage,
  valueUnit,
  valueLabel,
  valueMetaFunction,
  valueMetaObject,
  baselineAvailable,
  isIncreasingStatistic,
  valueBaseline,
  valueBaselineStdDev,
  valueInsight,
  backgroundColor,
  valueDescriptorNameColor,
  hideInfoButton,
  marginTop,
  onInfoButtonClick
}) => {

  const valueMeta = !!(valueMetaObject) ? valueMetaObject : valueMetaFunction(value, baselineAvailable, valueBaseline, valueBaselineStdDev)

  const baselineDeviation = ((value - valueBaseline) / valueBaseline)

  const arrowColor = valueDescriptorNameColor || valueMeta.color

  const valueAvailable = !!(value >= 0)

  return (
    <div style={{marginTop: marginTop || "min(6vw, 25px)", padding: "min(4vw, 20px)", backgroundColor: backgroundColor || "#ffffff", boxShadow: "0px 1px 1.5px 0.5px #dddddd", fontSize: "min(6vw, 25px)"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
        <div style={{display: "inline-block", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(5vw, 22px)"}}>
          {title}
        </div>
        {(!hideInfoButton) && (
          <SolidInfoSvg padding={"0px 0px 0px 0.3em"} onClick={() => onInfoButtonClick()} />
        )}
      </div>
      <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "max(1vw, 6px)"}}>
        {valueAvailable ? (
          <div style={{fontFamily: "Futura", fontSize: "min(7vw, 32px)"}}>
            {isPercentage ? formatPercentage(value / 100.0, valueDecimalPoints) : formatUnitNumber(value, valueUnit || valueLabel, valueDecimalPoints)}
          </div>
        ) : (
          <div style={{fontFamily: "Futura Book", textTransform: "uppercase", fontSize: "min(5vw, 22px)", color: "#747a8c"}}>
            {i18next.t('pending')}
          </div>
        )}
        <span style={{fontFamily: "Futura", fontSize: "min(2.5vw, 10px)", margin: "0px 0.5em"}}>
          {""}
        </span>
        {valueAvailable && baselineAvailable && (
          <svg style={{height: "min(4vw, 18px)", width: "min(4vw, 18px)", marginInlineStart: "0.2em", transform: baselineDeviation < 0 ? "rotate(180deg)" : "none"}} width="63px" height="63px" viewBox="0 0 63 63" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="noun-arrow-1920950" transform="translate(0.800000, 0.300000)"><rect id="Rectangle" x="0" y="0" width="62" height="62"></rect><polygon id="Path" fill={arrowColor} fillRule="nonzero" points="8 22.6 13.4 28 26.9 14.4 26.9 61.9 34.5 61.9 34.5 14.4 48 28 53.4 22.6 30.7 0"></polygon></g></g></svg>
        )}
        {valueAvailable && baselineAvailable && (
          <span style={{fontFamily: "Futura", fontSize: "min(5vw, 22px)", color: arrowColor}}>
            {formatPercentage(Math.abs(baselineDeviation), 1)}
          </span>
        )}
      </div>
      {(valueAvailable && !!(valueMeta.description)) && (
        <div style={{fontFamily: "Futura Book", fontSize: "min(4vw, 18px)", marginTop: "max(1vw, 6px)"}}>
          {valueMeta.description}
        </div>
      )}
    </div>
  )
}

export default HrvResultsPanel