import React, { Suspense } from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';


import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../../constants/requestStates'

import {
  sanitizeEmailAddress,
  ProductionCertificationUrl,
  formatReadableUnixTimestampMins,
} from '../../../utils'

import {
  ActivityArrowSvg,
} from '../../../constants/svgs'

import {
  RefreshSvg,
} from '../../../constants/dashboardSvgs'


class DelphiConversation extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    window.delphi = {...(window.delphi ?? {}) };
    window.delphi.page = {
      config: "bea097bf-18cf-45bf-9c8c-3aafb03581ea",
      overrides: {
        landingPage: "OVERVIEW",
      },
      container: {
        width: "100%",
        height: "calc(100vh - 85px)",
      },
    };
  }

  render() {
    return (
      <div>
        <script id="delphi-page-script"></script>
        <script id="delphi-page-bootstrap" src="https://embed.delphi.ai/loader.js"></script>
      </div>
    )
  }
}


class ClearBusinessTab extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        codeIsCopied: false,
        clientDetails: {
          client_first_name: "",
          client_email_address: "",
          months_app_access: 0
        },
        selectedSubView: "create",
        dropDownIsOpen: false
     }
  }

  componentWillUnmount() {
    
  }

  render() {

    const {
      timeOfDay,
      firstName,
      isActiveCoach,
      isDesktop
    } = this.props

    return (
      <div
        style={{marginTop: isDesktop ? (isActiveCoach ? 85 : 100) : 0, display: "flex", width: "100%", justifyContent: "center"}}
      >
        {isActiveCoach ? (
          <div style={{width: "100%", height: "calc(100vh - 85px)"}}>
            <DelphiConversation />
          </div>
        ) : (
          <div style={{width: "85%", maxWidth: 1000}}>
            <div style={{fontSize: "min(7vw, 30px)", fontFamily: "Futura", marginTop: "6vh"}}>
              {`UNLOCK CLEAR BUSINESS`}
            </div>
            <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Book", maxWidth: 500, marginTop: "2vh"}}>
              <span>{`Join the NEUROFIT Coach Certification to unlock CLEAR Business, figure out your core coaching offers and how they fit together, and launch more effectively:`}</span>
            </div>
            <div style={{display: "flex", justifyContent: "start", marginTop: "2vh"}}>
              <button
                style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", marginTop: 10, color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", transition: "color 200ms", border: "none"}}
                onClick={() => {
                  window.open(ProductionCertificationUrl, "_blank", "noopener noreferrer")
                }}
              >
                <span>
                  {"VIEW CERTIFICATION"}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ClearBusinessTab