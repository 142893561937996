import React from 'react'

import Checkbox from './Checkbox'

const ConfirmSection = ({
  label,
  isConfirmed,
  onToggleConfirm,
  isDisabled,
  marginTop,
  marginBottom,
  fontFamily,
  checkboxClass,
  color
}) => {
  return (
    <div style={{marginTop, marginBottom}}>
      <div style={{display: "flex", alignItems: "flex-start"}}>
        <Checkbox
          isChecked={isConfirmed}
          isDisabled={isDisabled}
          setIsChecked={() => onToggleConfirm(!isConfirmed)}
          checkboxClass={checkboxClass || "checkbox-white"}
          style={{display: "inline-block", marginInlineEnd: 10, marginTop: "min(3px, 1vh)"}}
        />
        <span style={{color, fontSize: "min(5vw, 20px)", textAlign: "start", fontFamily: fontFamily || "Futura Light"}}>
          {label}
        </span>
      </div>
    </div>
  );
}

export default ConfirmSection;